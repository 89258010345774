/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-14 14:16:20
 * @Module: 正在开发
 */
 <template>
  <div class="develop-box">
    <img src="@/static/homepage/develop.png"
         alt=""
         srcset="">
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.develop-box {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>